// axiosClient.ts
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { isTokenExpired, refreshAccessToken, getAccessToken } from '@/lib/auth';
import { getConfigEnv } from '@/utils/helpers';


const stage =  getConfigEnv("STAGE")
const BASE_URL = `https://rocksetapi-${stage}.tasqinc.com/${stage}`; // Replace with your base URL

const axiosClient: AxiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosClient.interceptors.request.use(async (config: AxiosRequestConfig) => {
  if (isTokenExpired()) {
    await refreshAccessToken();
  }
  const accessToken = getAccessToken();
  config.headers['Authorization'] = `${accessToken}`;
  return config;
});

export default axiosClient;
